import React, { createContext, FC, useCallback, useContext, useEffect, useState } from 'react';

export interface IContextState {
  showValidationResult: boolean;
}

export interface IContextActions {
  openValidationResult: () => void;
  closeValidationResult: () => void;
}

const initialState: IContextState = {
  showValidationResult: false,
};

const ComponentContext = createContext<IContextState & Partial<IContextActions>>(initialState);

interface IProviderProps {
  children: any;
}

export const FormValidationReportProvider: FC<IProviderProps> = ({ children }) => {
  const [showValidationResult, setShowValidationResult] = useState<boolean>(
    initialState.showValidationResult
  );

  useEffect(() => {
    console.log('showValidationResult', showValidationResult);
  }, [showValidationResult]);

  const openValidationResult = useCallback(() => {
    setShowValidationResult(true);
  }, []);
  const closeValidationResult = useCallback(() => {
    setShowValidationResult(false);
  }, []);

  return (
    <ComponentContext.Provider
      value={{
        showValidationResult,
        openValidationResult,
        closeValidationResult,
      }}
    >
      {children}
    </ComponentContext.Provider>
  );
};

export const useFormValidationReportContext = () => useContext(ComponentContext);
