import React, { createContext, FC, useContext, useEffect, useState, useCallback } from 'react';

import { projectFolderUpdateVariables } from 'graphql/legalFolders/types/projectFolderUpdate';

import './index.scss';

import { pick } from 'lodash';

import { validators } from 'constants/validators';
import { paths } from 'constants/index';

import validate from 'validate.js';
import {
  projectFolder_contract_container,
  projectFolder_contract_container_contacts,
} from 'graphql/legalFolders/types/projectFolder';

import { ApolloError, useApolloClient, useMutation } from '@apollo/client';

import { ISelectedParty } from './components/SelectParty/SelectParty';

import { useHistory, useLocation } from 'react-router-dom';

import {
  PROJECT_FOLDER_CREATE_MUTATION,
  PROJECT_FOLDER_UPDATE_MUTATION,
  PROJECT_FOLDER_SOFT_DELETE,
} from 'graphql/legalFolders/legalFolders';

import { CONTACT_UPDATE_MUTATION, CONTACT_CREATE_MUTATION } from 'graphql/legalFolders/contacts';

import { useUI } from 'contexts/UiContext';

import { apolloErrorHandler } from 'utils/apolloErrorHandler';
import { MonetaryTransactionType } from 'graphql/legalFolders/types/graphql-types';

import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';
import { legalFolderAttributes_contract_legalFolder } from 'graphql/legalFolders/types/legalFolderAttributes';
import {
  contractProposal,
  contractProposal_contract_proposal,
} from 'graphql/legalFolders/types/contractProposal';
import { GET_CONTRACT_PROPOSAL } from 'graphql/legalFolders/contractProposals';
import { MAX_MEMBERS, MAX_OWNERS } from 'constants/config';
import { useFormValidationReportContext } from 'components/FormValidationReport/FormValidationReportContext';
import { validatorOptions } from 'template/LegalFolder/LegalFolderContext';
export interface IContact extends Omit<projectFolder_contract_container_contacts, '__typename'> {
  changed?: boolean;
}
interface IProposal extends Omit<contractProposal_contract_proposal, '__typename'> {}

interface IProjectsListItem {
  key: string;
  name: string;
}

interface IOwner {
  id: string;
  name: string;
  email: string | null;
}

interface IMember {
  id: string;
  name: string;
  email: string | null;
}

interface ILegalFolderData
  extends projectFolderUpdateVariables,
    Pick<
      projectFolder_contract_container,
      | 'documentsDivisions'
      | 'documentsIndemnities'
      | 'documentsMonetaryTransactionValueSum'
      | 'documentsMonetaryTransactionType'
    > {
  contacts: IContact[];
  projects: IProjectsListItem[];
  deletedContacts: string[];
  isValid: boolean;
  showValidator: boolean;
  errors?: any;
  createdAt?: Date;
  updatedAt?: Date;
  owners: IOwner[] | null;
  members: IMember[] | null;
  documentsMonetaryTransactionType: MonetaryTransactionType;
  proposals: Array<IProposal | null>;
  partyIds: string[];
  legalFolderId: string;
  legalFolderName: string;
}

export interface IContact extends Omit<projectFolder_contract_container_contacts, '__typename'> {}

const projectFolderValidators = {
  name: validators.nameText,
};

const newLegalFolder: ILegalFolderData = {
  id: '',
  name: '',
  contacts: [],
  isValid: true,
  showValidator: false,
  deletedContacts: [],
  projects: [],
  documentsDivisions: [],
  documentsIndemnities: null,
  documentsMonetaryTransactionValueSum: null,
  documentsMonetaryTransactionType: MonetaryTransactionType.REVENUE,
  owners: [],
  members: [],
  proposals: [],
  partyIds: [],
  notifyMembers: true,
  notifyOwners: true,
  legalFolderId: '',
  legalFolderName: '',
};

export interface IContextState {
  projectFolder: ILegalFolderData;
  selectedParty?: any;
  loading?: boolean;
  legalFolderId?: string;
}

export interface IContextActions {
  onSetLegalFolderName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setSelectedParty: (selected: ISelectedParty) => void;
  onNewPartyCreate: () => void;
  onAddNewContact: () => void;
  onAddNewRevenueYear: () => void;
  onSetLegalFolder: (cb: (oldLegalFolder: ILegalFolderData) => ILegalFolderData) => void;
  onSubmit: () => void;
  onCancel: () => void;
  setContact: (index: number, contact: IContact) => void;
  deleteContact: (index: number) => void;
  onSelectProjectsChange: (selectedProjectsList: IProjectsListItem[]) => void;
  onSelectOwnersChange: (selectedownersList: IOwner[]) => void;
  onSelectMembersChange: (selectedMembersList: IMember[]) => void;
  onDeleteProcess: () => Promise<boolean>;
  onAddProposalBlock: () => void;
  onAddProposal: (id: string) => void;
  onRemoveProposal: (index: number) => void;
}

const initialState = {
  projectFolder: newLegalFolder,
  legalFolderId: '',
};

const ComponentContext = createContext<IContextState & Partial<IContextActions>>(initialState);

interface IProviderProps {
  loadedProjectFolder?: projectFolder_contract_container | null;
  legalFolderAttributes?: legalFolderAttributes_contract_legalFolder;
  refetch?: any;
  loading?: boolean;
  legalFolderId: string;
  onCreated?: (id: string) => void;
  children: any;
}

export const Provider: FC<IProviderProps> = ({
  legalFolderAttributes,
  loadedProjectFolder,
  refetch,
  loading,
  children,
  legalFolderId,
  onCreated,
}) => {
  const { openValidationResult } = useFormValidationReportContext();
  const { formChanged, resetChanged } = useFormChangedDialogContext();
  const [projectFolder, setLegalFolder] = useState<ILegalFolderData>(newLegalFolder);
  const { addSnackbar } = useUI();
  const client = useApolloClient();

  const history = useHistory();
  const location = useLocation();

  const [createLegalFolderMutation] = useMutation(PROJECT_FOLDER_CREATE_MUTATION);
  const [updateLegalFolderMutation] = useMutation(PROJECT_FOLDER_UPDATE_MUTATION);

  const [contactCreateMutation] = useMutation(CONTACT_CREATE_MUTATION);
  const [contactUpdateMutation] = useMutation(CONTACT_UPDATE_MUTATION);

  const [deleteProjectFolderMutation] = useMutation(PROJECT_FOLDER_SOFT_DELETE);

  const getProposalQuery = useCallback(
    async (variables: any) => {
      let proposal: contractProposal_contract_proposal | null = null;
      try {
        const { data } = await client.query<contractProposal>({
          query: GET_CONTRACT_PROPOSAL,
          variables,
          fetchPolicy: 'network-only',
        });
        proposal = data?.contract_proposal;
      } catch (error) {
        apolloErrorHandler(addSnackbar!)(error as ApolloError);
      }

      if (proposal) {
        setLegalFolder((legalFolder) => {
          const { proposals } = legalFolder;
          const newProposals = [...proposals];
          if (newProposals.length > 0 && newProposals.slice(-1)[0] === null) {
            newProposals.pop();
          }
          const foundIndex = newProposals.findIndex(
            (checkParty) => checkParty && checkParty.id === proposal!.id
          );
          if (foundIndex >= 0) {
            newProposals[foundIndex] = proposal;
          } else {
            newProposals.push(proposal);
          }
          return { ...legalFolder, proposals: newProposals };
        });
      }
    },
    [client, addSnackbar]
  );

  const memberOwnerCheckDuplicates = useCallback((newState: any) => {
    const { members, owners } = newState;
    if (!members?.length || !owners?.length) {
      return undefined;
    }
    const ownerIds = owners.map((owner: any) => owner.id);
    const fundMembers = members.filter((member: any) => ownerIds.includes(member.id));

    if (fundMembers?.length) {
      return (
        'Owners and Members duplicates: ' + fundMembers.map((member: any) => member.name).join(', ')
      );
    }

    return undefined;
  }, []);

  const validateForm = useCallback(
    (newState: any) => {
      let errors = validate(newState, projectFolderValidators, validatorOptions);
      const memberDuplicates = memberOwnerCheckDuplicates(newState);
      if (memberDuplicates) {
        errors = { ...errors, members: [memberDuplicates] };
      }

      const owners = newState.owners;
      if (!owners?.length && newState.id) {
        errors = { ...errors, owners: ['At least one project folder owner is required'] };
      }

      return { ...newState, errors, isValid: errors ? false : true };
    },
    [memberOwnerCheckDuplicates]
  );

  useEffect(() => {
    if (loadedProjectFolder) {
      const {
        id,
        name,
        notes,
        contacts,
        proposals,
        owners,
        members,
        documentsDivisions,
        documentsIndemnities,
        documentsMonetaryTransactionValueSum,
        projects,
        documentsMonetaryTransactionType,
        startDate,
        endDate,
        createdAt,
        updatedAt,
        legalFolder,
        notifyOwners,
        notifyMembers,
      } = loadedProjectFolder;

      const mappedOwners: IOwner[] | undefined = owners
        ?.filter((owner) => owner && owner.user)
        .map((owner) => {
          const { id, email, name } = owner!.user!;
          return {
            id,
            email,
            name,
          };
        });

      const mappedMembers: IMember[] | undefined = members
        ?.filter((member) => member && member.user)
        .map((member) => {
          const { id, email, name } = member!.user!;
          return {
            id,
            email,
            name,
          };
        });

      setLegalFolder({
        id,
        name,
        notes,
        contacts: [...contacts],
        deletedContacts: [],
        documentsDivisions,
        documentsIndemnities,
        documentsMonetaryTransactionValueSum,
        isValid: true,
        showValidator: false,
        projects: projects.map((project) => {
          return { key: project.id, name: project.key + ' - ' + project.name };
        }),
        documentsMonetaryTransactionType,
        startDate,
        endDate,
        createdAt,
        updatedAt,
        owners: mappedOwners || [],
        members: mappedMembers || [],
        proposals: [...proposals],
        partyIds: [...legalFolder.parties.map((party) => party.id)],
        notifyOwners,
        notifyMembers,
        legalFolderId: (legalFolder as any).id,
        legalFolderName: (legalFolder as any).name,
      });
    } else {
      if (legalFolderAttributes) {
        const { id: legalFolderId, name: legalFolderName } = legalFolderAttributes;
        setLegalFolder((oldState) => ({
          ...oldState,
          legalFolderId,
          legalFolderName,
        }));
      }
    }
  }, [loadedProjectFolder, legalFolderAttributes]);

  useEffect(() => {
    if (legalFolderAttributes) {
      const { documentsMonetaryTransactionType, parties } = legalFolderAttributes;
      setLegalFolder((old) => ({
        ...old,
        documentsMonetaryTransactionType,
        partyIds: parties.map((party) => party.id),
      }));
    }
  }, [legalFolderAttributes]);

  const onSetLegalFolderName = (e: React.ChangeEvent<HTMLInputElement>) => {
    formChanged && formChanged();
    setLegalFolder(validateForm({ ...projectFolder, name: e.target.value }));
  };

  const onNewPartyCreate = useCallback(() => {
    const partyEditPath = `${location.pathname}/new-party`;
    history.push(partyEditPath);
  }, [history, location.pathname]);

  const onSetLegalFolder = (cb: (oldState: ILegalFolderData) => ILegalFolderData) => {
    formChanged && formChanged();
    setLegalFolder((oldState) => {
      const newState = cb(oldState);
      return validateForm(newState);
    });
  };

  const updateProcedure = async ({
    mutation,
    parseResult,
  }: {
    mutation: any;
    parseResult: any;
  }) => {
    let result = undefined;
    try {
      addSnackbar!({
        text: 'please wait ...',
        severity: 'info',
      });
      // await apolloClient.resetStore();
      const { data } = await mutation();
      result = parseResult(data);
      if (result) {
        // refetch && refetch();
        addSnackbar!({
          text: 'Project folder is saved',
          severity: 'success',
        });
      } else {
        addSnackbar!({
          text: 'Unable to process request, please try again',
          severity: 'error',
        });
      }
    } catch (error) {
      apolloErrorHandler(addSnackbar!)(error as ApolloError);
    }
    return result;
  };

  const onSubmitValidate = useCallback(() => {
    const { isValid, name, owners, members, id } = projectFolder;
    const validationResult = validateForm(projectFolder);
    const { errors } = validationResult;

    if (
      !!errors ||
      !isValid ||
      !name ||
      (parseInt(id) > 0 && (!owners || owners?.length > MAX_OWNERS || owners.length < 1)) ||
      (members && members?.length > MAX_MEMBERS)
    ) {
      setLegalFolder({ ...validationResult, showValidator: true });
      openValidationResult && openValidationResult();
      return false;
    }
    return true;
  }, [projectFolder, validateForm, openValidationResult]);

  const onCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  const onSubmit = async () => {
    if (!onSubmitValidate()) {
      setTimeout(() => window.scrollTo({ top: 0 }), 0);
      return;
    }

    let contactsError = false;
    for (const contact of projectFolder.contacts) {
      if (contact.changed) {
        let contactId = undefined;
        try {
          if (parseInt(contact.id) > 0) {
            const { data } = await contactUpdateMutation({ variables: contact });
            contactId = data?.contract_contactUpdate?.id;
          } else {
            const { data } = await contactCreateMutation({ variables: contact });
            contactId = data?.contract_contactCreate?.id;
          }
          contact.id = contactId;
        } catch (error) {
          apolloErrorHandler(addSnackbar!)(error as ApolloError);
          contactsError = true;
        }
      }
    }

    if (contactsError) {
      addSnackbar!({ text: 'Contact update error', severity: 'error' });
    }

    const contactIdsToSave: string[] = projectFolder.contacts
      .map((contact) => contact.id)
      .filter((id) => parseInt(id) > 0);
    const oldContactIds: string[] =
      loadedProjectFolder?.contacts.map((contact) => contact.id) || [];

    const projectIdsToSave: string[] = projectFolder.projects.map((project) => project.key);
    const oldProjectIds: string[] =
      loadedProjectFolder?.projects.map((project) => project.id) || [];

    const proposalIdsToSave: string[] = projectFolder.proposals
      .map((proposal) => (proposal ? proposal.id : '0'))
      .filter((id) => parseInt(id) > 0);
    const oldProposalIds: string[] =
      loadedProjectFolder?.proposals.map((proposal) => proposal.id) || [];

    if (parseInt(projectFolder.id) > 0) {
      const projectIdsToRemove = oldProjectIds.filter((id) => !projectIdsToSave?.includes(id));
      const projectIdsToAdd = projectIdsToSave.filter((id) => !oldProjectIds?.includes(id));

      const oldMembersIds = loadedProjectFolder?.members?.map((member) => member.user?.id) || [];
      const newMembersIds = projectFolder.members?.map((member) => member.id) || [];

      const memberUserIdsToAdd = newMembersIds.filter((itemId) => !oldMembersIds.includes(itemId));
      const memberUserIdsToRemove = oldMembersIds.filter(
        (itemId) => !newMembersIds.includes(itemId!)
      );

      const oldOwnerIds = loadedProjectFolder?.owners?.map((owner) => owner.user?.id) || [];
      const newOwnerIds = projectFolder.owners?.map((owner) => owner.id) || [];

      const ownerUserIdsToAdd = newOwnerIds.filter((itemId) => !oldOwnerIds.includes(itemId));
      const ownerUserIdsToRemove = oldOwnerIds.filter((itemId) => !newOwnerIds.includes(itemId!));

      const saveData = {
        ...pick(projectFolder, [
          'id',
          'name',
          'notes',
          'notifyOwners',
          'notifyMembers',
          'startDate',
          'endDate',
        ]),
        contactIdsToAdd: contactIdsToSave.filter((id) => !oldContactIds?.includes(id)),
        contactIdsToRemove: oldContactIds.filter((id) => !contactIdsToSave?.includes(id)),
        projectIdsToAdd: projectIdsToAdd && projectIdsToAdd.length ? projectIdsToAdd : undefined,
        projectIdsToRemove:
          projectIdsToRemove && projectIdsToRemove.length ? projectIdsToRemove : undefined,
        proposalIdsToAdd: proposalIdsToSave.filter((id) => !oldProposalIds?.includes(id)),
        proposalIdsToRemove: oldProposalIds.filter((id) => !proposalIdsToSave?.includes(id)),
        memberUserIdsToAdd,
        ownerUserIdsToRemove,
        memberUserIdsToRemove,
        ownerUserIdsToAdd,
      };
      const updateLegalFolderId = await updateProcedure({
        mutation: () =>
          updateLegalFolderMutation({
            variables: saveData,
          }),
        parseResult: (data: any) => data?.contract_containerUpdate?.id,
      });

      if (updateLegalFolderId) {
        await client.resetStore();
      }
    } else {
      const saveData = {
        legalFolderId,
        ...pick(projectFolder, [
          'name',
          'notes',
          'notifyOwners',
          'notifyMembers',
          'startDate',
          'endDate',
        ]),
        contactIds: contactIdsToSave,
        projectIds: projectIdsToSave,
        memberUserIds:
          projectFolder.members?.filter((member) => member !== null).map((member) => member.id) ||
          [],
      };

      const newLegalFolderId = await updateProcedure({
        mutation: () =>
          createLegalFolderMutation({
            variables: saveData,
          }),
        parseResult: (data: any) => data?.contract_containerCreate?.id,
      });

      if (newLegalFolderId) {
        await client.resetStore();
        if (onCreated) {
          onCreated(newLegalFolderId);
        } else {
          const newPath = paths.client.PROJECT_FOLDER_ID.replace(':id', newLegalFolderId);
          history.push(newPath);
        }
      }
    }
    resetChanged && resetChanged();
  };

  const onAddNewContact = useCallback(() => {
    formChanged && formChanged();
    setLegalFolder((oldLegalFolder) => {
      return {
        ...oldLegalFolder,
        contacts: [...oldLegalFolder.contacts, { id: '', name: '', email: '', phone: '' }],
      };
    });
  }, [formChanged]);

  const setContact = useCallback(
    (index: number, newContact: any) => {
      formChanged && formChanged();
      setLegalFolder((oldLegalFolder) => {
        return {
          ...oldLegalFolder,
          contacts: oldLegalFolder.contacts
            .slice(0, index)
            .concat({ ...newContact, changed: true })
            .concat(oldLegalFolder.contacts.slice(index + 1)),
        };
      });
    },
    [formChanged]
  );

  const deleteContact = useCallback(
    (index: number) => {
      formChanged && formChanged();
      setLegalFolder((oldLegalFolder) => {
        return {
          ...oldLegalFolder,
          contacts: oldLegalFolder.contacts
            .slice(0, index)
            .concat(oldLegalFolder.contacts.slice(index + 1)),
          deletedContacts: oldLegalFolder.deletedContacts.concat(
            parseInt(oldLegalFolder.contacts[index].id) > 0
              ? [oldLegalFolder.contacts[index].id]
              : []
          ),
        };
      });
    },
    [formChanged]
  );

  const onSelectProjectsChange = useCallback(
    (selectedProjectsList: IProjectsListItem[]) => {
      formChanged && formChanged();
      setLegalFolder((legalFolder) => {
        return { ...legalFolder, projects: selectedProjectsList };
      });
    },
    [setLegalFolder, formChanged]
  );

  const onDeleteProcess = useCallback(async () => {
    let success = false;
    const variables = { containerId: projectFolder.id };
    try {
      const { data } = await deleteProjectFolderMutation({
        variables,
      });
      if (data?.contract_containerSoftDelete) {
        addSnackbar!({ text: 'Project Folder is deleted', severity: 'success' });
        success = true;
      } else {
        addSnackbar!({ text: 'Unable to process request, please try again', severity: 'error' });
      }
    } catch (error) {
      apolloErrorHandler(addSnackbar!)(error as ApolloError);
    }
    if (success) {
      await client.resetStore();
    }
    return success;
  }, [addSnackbar, deleteProjectFolderMutation, projectFolder, client]);

  const onSelectOwnersChange = useCallback(
    (selectedOwnersList: IOwner[] | null) => {
      formChanged && formChanged();
      setLegalFolder((legalFolder) => {
        const selectedOwnersIds = selectedOwnersList?.map((owner) => owner.id);
        const members = legalFolder.members?.filter(
          (member) => !selectedOwnersIds?.includes(member.id)
        );
        return validateForm({ ...legalFolder, owners: selectedOwnersList, members });
      });
    },
    [setLegalFolder, formChanged, validateForm]
  );

  const onSelectMembersChange = useCallback(
    (selectedMembersList: IMember[] | null) => {
      formChanged && formChanged();
      setLegalFolder((legalFolder) => {
        return validateForm({ ...legalFolder, members: selectedMembersList });
      });
    },
    [setLegalFolder, formChanged, validateForm]
  );

  const onAddProposalBlock = useCallback(() => {
    formChanged && formChanged();
    setLegalFolder((legalFolder) => {
      const { proposals } = legalFolder;
      return { ...legalFolder, proposals: [...proposals, null] };
    });
  }, [formChanged]);

  const onAddProposal = useCallback(
    (id: string) => {
      if (!projectFolder.proposals.find((proposal) => proposal?.id === id)) {
        const variables = { id };
        getProposalQuery(variables);
      }
    },
    [getProposalQuery, projectFolder.proposals]
  );

  const onRemoveProposal = useCallback(
    (index: number) => {
      console.log('Remove proposal', index);
      formChanged && formChanged();
      setLegalFolder((projectFolder) => {
        const { proposals } = projectFolder;
        return {
          ...projectFolder,
          proposals: [...proposals.slice(0, index), ...proposals.slice(index + 1)],
        };
      });
    },
    [formChanged]
  );

  return (
    <ComponentContext.Provider
      value={{
        projectFolder,
        onSetLegalFolderName,
        onNewPartyCreate,
        onAddNewContact,
        onSetLegalFolder,
        onSubmit,
        onCancel,
        setContact,
        deleteContact,
        onSelectProjectsChange,
        loading: loading,
        legalFolderId,
        onDeleteProcess,
        onSelectOwnersChange,
        onSelectMembersChange,
        onAddProposalBlock,
        onAddProposal,
        onRemoveProposal,
      }}
    >
      {children}
    </ComponentContext.Provider>
  );
};

export const useComponentContext = () => useContext(ComponentContext);
