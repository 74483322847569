import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useComponentContext } from '../../LegalFolderContext';
import LoadingOverlay from 'react-loading-overlay-ts';
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { LegalFolderFormFields } from '../LegalFolderFormFields/LegalFolderFormFields';
import { SaveAlt } from '@mui/icons-material';
import { Print } from 'template/Print/Print';
import { LegalFolderPrintingMeta } from '../LegalFolderPrintingMeta/LegalFolderPrintingMeta';
import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';
import { useHistory } from 'react-router-dom';
import { paths } from 'constants/index';
import { AlertDialog, ConfirmationDialog } from 'components';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
import { joinStringArray } from 'utils/formats';

import { relatedLegalFoldersTableHead } from 'constants/relatedLegalFoldersTable';
import { DataSourceTable } from './components/DataSourceTable/DataSourceTable';
import { FormValidationReport } from 'components/FormValidationReport/FormValidationReport';
import { useFormValidationReportContext } from 'components/FormValidationReport/FormValidationReportContext';

export const LegalFolderForm: FC = () => {
  const history = useHistory();
  const { resetChanged } = useFormChangedDialogContext();
  const { showValidationResult: showValidationReportResult } = useFormValidationReportContext();
  const {
    onSubmitValidate,
    onSubmit,
    loading,
    onDeleteProcess,
    legalFolder,
    onValidateLegalFolderDetails,
    validateLegalFolderNameResult,
    validateLegalFolderPartiesResult,
    similarLegalFolders,
    validateLegalFolderNameTableDataSource,
    validateLegalFolderPartiesTableDataSource,
    similarLegalFoldersTableDataSource,
  } = useComponentContext();
  const [printing, setPrinting] = useState(false);
  const [displayDeleteWarning, showDeleteWarning] = useState(false);
  const [showValidationResult, setShowValidationResult] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [legalFolder.id]);

  // Workaround to override scrolling down when report closed
  useEffect(() => {
    if (!showValidationReportResult) {
      setTimeout(() => window.scrollTo({ top: 0 }), 0);
    }
  }, [showValidationReportResult]);

  const handleSubmit = useCallback(async () => {
    if (onSubmitValidate && onSubmitValidate()) {
      if (
        !!legalFolder.id ||
        (onValidateLegalFolderDetails && (await onValidateLegalFolderDetails()))
      ) {
        onSubmit && onSubmit();
      } else {
        setShowValidationResult(true);
      }
      setTimeout(() => window.scrollTo({ top: 0 }), 0);
    }
  }, [legalFolder.id, onSubmitValidate, onSubmit, onValidateLegalFolderDetails]);

  const handleDeleteLegalFolder = useCallback(
    async (confirm: boolean) => {
      if (confirm) {
        showDeleteWarning(() => false);
        if (await onDeleteProcess!()) {
          history.push(paths.client.BASE);
          resetChanged && resetChanged();
        }
      }
    },
    [onDeleteProcess, history, resetChanged]
  );

  const handleCreateLegalFolder = useCallback(
    async (confirm: boolean) => {
      if (confirm) {
        onSubmit!();
      }
      setShowValidationResult(false);
    },
    [onSubmit]
  );

  const onLegalFolderSelect = useCallback(
    (id: string) => {
      const newUrl = paths.treeViewClient.TREEVIEW_LEGAL_FOLDER.replace(':id', id);
      history.push(newUrl);
      return true;
    },
    [history]
  );

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const validationResultMessage = useMemo(() => {
    return (
      <div>
        {validateLegalFolderNameResult ? (
          <>
            Legal Folder "{validateLegalFolderNameResult?.name}" exists.
            {validateLegalFolderNameResult.currentUserHasAccessRights === false ? (
              <>
                <br />
                You don't have access rights to that Legal Folder.
              </>
            ) : undefined}
            <br />
            <br />
            <DataSourceTable
              dataSource={validateLegalFolderNameTableDataSource}
              tableHead={relatedLegalFoldersTableHead}
              onSelect={onLegalFolderSelect}
            ></DataSourceTable>
          </>
        ) : undefined}
        {validateLegalFolderPartiesResult?.length ? (
          <>
            <Box m={3} />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Divider />
                  <Box m={3} />
                </Grid>
              </Grid>
            </Grid>
            Other {legalFolder.parties.filter((party) => !!party).length > 1 ? 'Parties' : 'Party'}{' '}
            {joinStringArray(legalFolder.parties.map((party) => '"' + party?.name + '"'))}{' '}
            {legalFolder.parties.filter((party) => !!party).length > 1 ? 'are' : 'is'} assigned to:
            <br />
            <br />
            <DataSourceTable
              dataSource={validateLegalFolderPartiesTableDataSource}
              tableHead={relatedLegalFoldersTableHead}
              onSelect={onLegalFolderSelect}
            ></DataSourceTable>
          </>
        ) : undefined}

        {similarLegalFolders?.filter((lf) => lf.name !== legalFolder.name).length ? (
          <>
            <Box m={3} />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Divider />
                  <Box m={3} />
                </Grid>
              </Grid>
            </Grid>
            Legal Folders with similar folder name to "{legalFolder.name}" are found:
            <br />
            <br />
            <DataSourceTable
              dataSource={similarLegalFoldersTableDataSource}
              tableHead={relatedLegalFoldersTableHead}
              onSelect={onLegalFolderSelect}
            ></DataSourceTable>
          </>
        ) : undefined}
      </div>
    );
  }, [
    validateLegalFolderNameResult,
    validateLegalFolderPartiesResult,
    similarLegalFolders,
    legalFolder.name,
    legalFolder.parties,
    validateLegalFolderNameTableDataSource,
    similarLegalFoldersTableDataSource,
    validateLegalFolderPartiesTableDataSource,
    onLegalFolderSelect,
  ]);

  return (
    <LoadingOverlay spinner active={loading} text="Loading your content...">
      <FormValidationReport
        errors={{
          ...legalFolder.errors,
        }}
      ></FormValidationReport>

      <LegalFolderFormFields></LegalFolderFormFields>

      <Print printing={printing} setPrinting={setPrinting} PrintingMeta={LegalFolderPrintingMeta}>
        <LegalFolderFormFields></LegalFolderFormFields>
      </Print>

      <AlertDialog
        title="Legal Folder Exists"
        message={validationResultMessage}
        open={showValidationResult && validateLegalFolderNameResult}
        onClose={() => {
          setShowValidationResult(false);
        }}
        maxWidth="lg"
        fullWidth
      />

      <ConfirmationDialog
        open={showValidationResult && !validateLegalFolderNameResult}
        title="Please confirm..."
        message={'Please confirm that you would like to create Legal Folder'}
        content={validationResultMessage}
        onClose={handleCreateLegalFolder}
        confirmButtonProps={{ style: { background: 'red' } }}
        maxWidth="lg"
        fullWidth
      />

      <ConfirmationDialog
        open={displayDeleteWarning}
        title="Please confirm Legal Folder deletion"
        message={'Selected Legal Folder will be deleted! The action is irreversible!'}
        onClose={handleDeleteLegalFolder}
        confirmButtonProps={{ style: { background: 'red' } }}
      />
      <Box m={5} />
      <Container maxWidth="md" style={!matches ? { padding: '0px' } : undefined}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2} alignContent="center" alignItems="center">
              <Grid item xs={12} md={4} lg={3}>
                <Typography variant="body2" className="label-title">
                  Cover sheet:
                </Typography>
              </Grid>
              <Grid item xs={11} md={4} lg={6}>
                <div
                  style={{
                    display: 'flex',
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '1em',
                    cursor: 'pointer',
                  }}
                  onClick={debounce(() => {
                    setPrinting(true);
                  }, DEBOUNCE_TIMEOUT)}
                >
                  <SaveAlt></SaveAlt>
                  <Button variant="outlined" color="primary">
                    Download
                  </Button>
                </div>
              </Grid>
              <Grid item xs={1} md={4} lg={3}></Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Box m={5} />
      <Grid container>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <Grid container justifyContent="center" spacing={3}>
            {legalFolder.id ? (
              <Grid item xs={12} sm={3} order={{ xs: 3, sm: 1 }}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={debounce(() => {
                    showDeleteWarning(true);
                  }, DEBOUNCE_TIMEOUT)}
                  style={{ background: 'red' }}
                >
                  Delete
                </Button>
              </Grid>
            ) : undefined}

            <Grid item style={{ width: '50px' }} xs={12} sm={1} order={{ xs: 2, sm: 2 }}></Grid>

            <Grid item xs={12} sm={3} order={{ xs: 1, sm: 3 }}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={debounce(handleSubmit, DEBOUNCE_TIMEOUT)}
                disabled={!legalFolder.name || !legalFolder.parties.find((party: any) => party?.id)}
              >
                Submit{/* {id ? 'Submit' : 'Create new'} */}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
    </LoadingOverlay>
  );
};
