import { useCallback, useMemo, useState } from 'react';

import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Divider,
  useTheme,
  useMediaQuery,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
  IconButton,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import './index.scss';

import { Contacts } from '../Contacts/Contacts';

import { useComponentContext } from '../../ProjectFolderContext';

import { SelectProject } from '../SelectProject/SelectProject';

import { MonetaryTransactionType } from 'graphql/legalFolders/types/graphql-types';
import { formatCurrencyPartsUSD } from 'utils/formats';
import { MAX_MEMBERS, MAX_OWNERS, NAME_LENGTH_LIMIT, PROPOSALS_APP_URL } from 'constants/config';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
import { SelectMultipleOwners } from 'template/LegalFolderDocument/components/DocumentView/components/SelectMultipleOwners/SelectMultipleOwners';
import { ProposalTiles } from 'template/LegalFolder/components/ProposalTiles/ProposalTiles';
import { useMsalAccount } from 'hooks/msalAccount';
import { PickersActionBarAction } from '@mui/x-date-pickers/PickersActionBar/PickersActionBar';
import { SelectMultipleMembers } from 'template/LegalFolderDocument/components/DocumentView/components/SelectMultipleMembers/SelectMultipleMembers';
import { useCurrentDbUser } from 'hooks/currentDbUserHook';
import { DriveFileMove as MoveIcon } from '@mui/icons-material';
import { DialogForm } from 'components/DialogForm/DialogForm';
import { MoveProjectFolder } from 'components/MoveProjectFolder/MoveProjectFolder';
import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';
import { tryUpdateProcedure } from 'utils/apollo';
import { useApolloClient, useMutation } from '@apollo/client';

import { useHistory } from 'react-router-dom';
import { paths } from '../../../../constants';
import { useUI } from 'contexts/UiContext';
import { CONTAINER_MOVE } from 'graphql/legalFolders/legalFolders';
import { useCurrentUser } from 'hooks/currentUserHook';

// const dateStyle = {
//   backgroundColor: '#fff',
//   borderRadius: 4,
//   border: '1px solid #aaa',
// };

export const ProjectFolderFormFields = () => {
  const { users: currentAccountUsers } = useCurrentUser();
  const history = useHistory();
  const client = useApolloClient();
  const { addSnackbar } = useUI();
  const [moveProjectFolderMutation] = useMutation(CONTAINER_MOVE);
  const { msalAccount: account } = useMsalAccount();
  const { user } = useCurrentDbUser();
  const { confirmRequest } = useFormChangedDialogContext();

  const isLegalAdmin = useMemo(() => {
    return !!user?.isContractsAdmin;
  }, [user]);

  const {
    projectFolder,
    onSetLegalFolderName,
    onAddNewContact,
    onSetLegalFolder,
    setContact,
    deleteContact,
    onSelectProjectsChange,
    onSelectOwnersChange,
    onSelectMembersChange,
    onRemoveProposal,
    onAddProposal,
    onAddProposalBlock,
  } = useComponentContext();

  const {
    name,
    showValidator,
    errors,
    startDate,
    endDate,
    notes,
    contacts,
    id,
    documentsMonetaryTransactionValueSum,
    documentsIndemnities,
    documentsDivisions,
    projects,
    documentsMonetaryTransactionType,
    createdAt,
    updatedAt,
    owners,
    members,
    proposals,
    partyIds,
    notifyOwners,
    notifyMembers,
    legalFolderId,
    legalFolderName,
  } = projectFolder;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const onViewProposal = useCallback(
    (index: number) => {
      if (proposals[index]?.id) {
        window.open(
          `${PROPOSALS_APP_URL}/proposal/${proposals[index]?.id}/details`,
          '_blank',
          'noopener,noreferrer'
        );
      }
    },
    [proposals]
  );

  // const onAddProposalV1 = useCallback((proposal) => {
  //   !!onAddProposal && onAddProposal(proposal.id);
  // }, []);

  const membersIncludesOwner = useMemo(() => {
    if (!members?.length || !owners?.length) {
      return false;
    }
    const ownerIds = owners?.map((owner) => owner.id);
    const found = members?.find((member) => ownerIds?.includes(member.id));
    return !!found;
  }, [members, owners]);

  const [openMoveProjectFolderDialog, setOpenMoveProjectFolderDialog] = useState(false);

  const onMoveClick = useCallback(() => {
    confirmRequest!((confirmed) => {
      confirmed && setOpenMoveProjectFolderDialog(true);
    });
  }, [setOpenMoveProjectFolderDialog, confirmRequest]);

  const onCloseMoveProjectFolderDialog = useCallback(() => {
    setSelectedLegalFolder(undefined);
    setOpenMoveProjectFolderDialog(false);
  }, [setOpenMoveProjectFolderDialog]);

  const [selectedLegalFolder, setSelectedLegalFolder] = useState<{ id: string; name: string }>();

  const moveProjectFolder = useCallback(async () => {
    setOpenMoveProjectFolderDialog(false);
    const { result, isError, errors } = await tryUpdateProcedure({
      mutation: () =>
        moveProjectFolderMutation({
          variables: {
            containerId: id,
            destinationLegalFolderId: selectedLegalFolder?.id,
          },
        }),
      parseResult: (data: any) => {
        return data;
      },
    });
    if (result && !isError) {
      await client.resetStore();
      history.push(paths.treeViewClient.TREEVIEW_PROJECT_FOLDER.replace(':id', id));
      addSnackbar && addSnackbar({ severity: 'success', text: 'Success' });
      addSnackbar &&
        addSnackbar({
          severity: 'info',
          text: 'Current Legal Folder has changed!',
        });
    } else {
      addSnackbar && addSnackbar({ severity: 'error', text: errors ? errors.join('. ') : 'Error' });
    }
  }, [client, history, id, moveProjectFolderMutation, addSnackbar, selectedLegalFolder]);

  return (
    <>
      <DialogForm
        dialogTitle={'Move Project Folder'}
        onClose={onCloseMoveProjectFolderDialog}
        open={openMoveProjectFolderDialog}
        maxWidth="md"
      >
        <div style={{ padding: '10px 40px 30px 30px' }}>
          <MoveProjectFolder
            onSelectLegalFolderChange={setSelectedLegalFolder}
            selectedLegalFolder={selectedLegalFolder}
            currentLegalFolder={{ id: legalFolderId, name: legalFolderName }}
            cancelHandler={onCloseMoveProjectFolderDialog}
            confirmHandler={moveProjectFolder}
          />
        </div>
      </DialogForm>
      <Container maxWidth="md" style={!matches ? { padding: '0px' } : undefined}>
        <Grid container padding={0} spacing={2}>
          <Grid xs={12}>
            <Box m={5} />

            <Grid container padding={0} spacing={2} alignContent="center" alignItems="center">
              <>
                <Grid xs={5} lg={4}>
                  <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Typography variant="body2" className="label-title">
                      Project Folder Name:
                    </Typography>
                    <div style={{ padding: '0 2px' }}>
                      <Typography variant="body2" className="label-title-desc">
                        (required)
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid xs={7} lg={8}>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={name}
                    onChange={onSetLegalFolderName}
                    fullWidth
                    error={showValidator && errors?.name}
                    inputProps={{ maxLength: NAME_LENGTH_LIMIT }}
                  />
                </Grid>

                <Grid xs={5} lg={4}>
                  <Typography variant="body2" className="label-title">
                    Legal folder name:
                  </Typography>
                </Grid>
                <Grid xs={7} lg={8}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flexGrow: 1 }}>
                      <TextField
                        variant="outlined"
                        size="small"
                        value={legalFolderName}
                        onChange={() => {}}
                        fullWidth
                        error={showValidator && errors?.name}
                        disabled={true}
                      />
                    </div>
                    {isLegalAdmin && !!id ? (
                      <div
                        onClick={debounce(onMoveClick, DEBOUNCE_TIMEOUT)}
                        style={{ cursor: 'pointer', paddingLeft: '10px' }}
                      >
                        <Tooltip title={'Change Legal Folder'} disableInteractive={true}>
                          <IconButton size="small">
                            <MoveIcon style={{ color: '#006ad4' }} fontSize="medium"></MoveIcon>
                          </IconButton>
                        </Tooltip>
                      </div>
                    ) : undefined}
                  </div>
                </Grid>

                <Grid xs={5} lg={4}>
                  <Box marginBottom={1}>
                    <Box marginBottom={1}>
                      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <Typography variant="body2" className="label-title">
                          Project's Proposal{proposals?.length && proposals.length > 1 ? 's' : ''}:
                        </Typography>
                      </div>
                    </Box>
                  </Box>
                </Grid>
                <Grid xs={7} lg={8}>
                  <ProposalTiles
                    proposals={proposals}
                    onAddProposal={onAddProposal!}
                    onRemoveProposal={onRemoveProposal!}
                    showValidator={showValidator}
                    onViewProposal={onViewProposal}
                    legalFolderPartyIds={partyIds}
                  ></ProposalTiles>
                </Grid>

                <Grid xs={3}>
                  <div style={{ width: 'fit-content', whiteSpace: 'nowrap' }}>
                    <Typography variant="body2" className="label-title">
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={
                          onAddProposalBlock && debounce(onAddProposalBlock, DEBOUNCE_TIMEOUT)
                        }
                        disabled={proposals.length > 0 && proposals.slice(-1)[0] === null}
                      >
                        Add Proposal
                      </Button>
                    </Typography>
                  </div>
                </Grid>
                <Grid xs={9}></Grid>

                <Grid xs={5} lg={4}>
                  <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Typography variant="body2" className="label-title">
                      Owners:
                    </Typography>
                    {!!id ? (
                      <div style={{ padding: '0 2px' }}>
                        <Typography variant="body2" className="label-title-desc">
                          (required)
                        </Typography>
                      </div>
                    ) : undefined}
                  </div>
                </Grid>
                <Grid xs={7} lg={8}>
                  {id.length ? (
                    <SelectMultipleOwners
                      selectedItems={owners}
                      onSelectChange={onSelectOwnersChange}
                      error={
                        showValidator && (!owners || !owners.length || owners.length > MAX_OWNERS)
                      }
                    ></SelectMultipleOwners>
                  ) : (
                    <TextField
                      variant="outlined"
                      size="small"
                      value={account?.name || ''}
                      onChange={() => {}}
                      fullWidth
                      error={false}
                      disabled={true}
                    />
                  )}
                </Grid>

                <Grid xs={5} lg={4}>
                  <Typography variant="body2" className="label-title">
                    Members:
                  </Typography>
                </Grid>
                <Grid xs={7} lg={8}>
                  <SelectMultipleMembers
                    selectedItems={members}
                    selectedOwners={
                      id.length
                        ? owners
                        : currentAccountUsers?.map(({ id, name, email }) => ({ id, name, email }))
                    }
                    onSelectChange={onSelectMembersChange}
                    error={
                      showValidator &&
                      !!members &&
                      (members.length > MAX_MEMBERS || !!membersIncludesOwner || errors?.members)
                    }
                  ></SelectMultipleMembers>
                </Grid>

                {id.length ? (
                  <>
                    <Grid xs={5} lg={4}>
                      <Typography variant="body2" className="label-title">
                        Created Date:
                      </Typography>
                    </Grid>
                    <Grid xs={7} lg={8}>
                      {createdAt
                        ? new Date(createdAt).toLocaleDateString('en-EN', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                          })
                        : '--'}
                    </Grid>

                    <Grid xs={5} lg={4}>
                      <Typography variant="body2" className="label-title">
                        Last Modified Date:
                      </Typography>
                    </Grid>
                    <Grid xs={7} lg={8}>
                      {updatedAt
                        ? new Date(updatedAt).toLocaleDateString('en-EN', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                          })
                        : '--'}
                    </Grid>
                  </>
                ) : undefined}

                <Grid xs={5} lg={4}>
                  <Typography variant="body2" className="label-title">
                    Notify owners:
                  </Typography>
                </Grid>
                <Grid xs={7} lg={8}>
                  <RadioGroup
                    row
                    aria-label="isOriginParty"
                    name="position"
                    value={notifyOwners ? 1 : 0}
                    onChange={() => {
                      onSetLegalFolder!((oldLegalFolder) => {
                        return {
                          ...oldLegalFolder,
                          notifyOwners: !oldLegalFolder.notifyOwners,
                        };
                      });
                    }}
                  >
                    <FormControlLabel value="1" control={<Radio color="primary" />} label="Yes" />
                    <FormControlLabel value="0" control={<Radio color="primary" />} label="No" />
                  </RadioGroup>
                </Grid>

                <Grid xs={5} lg={4}>
                  <Typography variant="body2" className="label-title">
                    Notify members:
                  </Typography>
                </Grid>
                <Grid xs={7} lg={8}>
                  <RadioGroup
                    row
                    aria-label="isOriginParty"
                    name="position"
                    value={notifyMembers ? 1 : 0}
                    onChange={() => {
                      onSetLegalFolder!((oldLegalFolder) => {
                        return {
                          ...oldLegalFolder,
                          notifyMembers: !oldLegalFolder.notifyMembers,
                        };
                      });
                    }}
                  >
                    <FormControlLabel value="1" control={<Radio color="primary" />} label="Yes" />
                    <FormControlLabel value="0" control={<Radio color="primary" />} label="No" />
                  </RadioGroup>
                </Grid>
              </>
            </Grid>
          </Grid>
        </Grid>

        <Box m={3} />

        <Grid container padding={0} spacing={2}>
          <Grid xs={12}>
            <Divider />
            <Box m={3} />
          </Grid>
        </Grid>

        <Grid container padding={0} spacing={2}>
          <Grid xs={12}>
            <Grid container padding={0} spacing={2} alignContent="center" alignItems="center">
              <>
                <Grid xs={5}>
                  <Typography variant="body2" className="label-title">
                    Other Contacts:
                  </Typography>
                </Grid>
                <Grid xs={7}></Grid>

                <Grid xs={12}>
                  <Contacts
                    contacts={contacts}
                    setContact={setContact!}
                    deleteContact={deleteContact!}
                  ></Contacts>
                </Grid>

                <Grid xs={5}>
                  <div style={{ width: 'fit-content', whiteSpace: 'nowrap' }}>
                    <Typography variant="body2" className="label-title">
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={onAddNewContact && debounce(onAddNewContact, DEBOUNCE_TIMEOUT)}
                      >
                        Add Contact
                      </Button>
                    </Typography>
                  </div>
                </Grid>
                <Grid xs={7}></Grid>
              </>
            </Grid>
          </Grid>
        </Grid>

        <Box m={3} />

        <Grid container padding={0} spacing={2}>
          <Grid xs={12}>
            <Divider />
            <Box m={3} />
          </Grid>
        </Grid>

        <Grid container padding={0} spacing={2} alignContent="center" alignItems="center">
          <Grid xs={5} lg={4}>
            <Typography variant="body2" className="label-title">
              Projects Assigned:
            </Typography>
          </Grid>
          <Grid xs={7} lg={8}>
            <SelectProject
              onSelectChange={onSelectProjectsChange}
              selectedItems={projects}
            ></SelectProject>
          </Grid>
        </Grid>

        <Grid container padding={0} spacing={2} alignContent="center" alignItems="center">
          <Grid xs={5} lg={4}>
            <Typography variant="body2" className="label-title">
              Divisions:
            </Typography>
          </Grid>
          <Grid xs={7} lg={8}>
            {!documentsDivisions || !documentsDivisions.length
              ? ''
              : documentsDivisions
                  ?.map((division) => {
                    return division.name;
                  })
                  .join(', ')}
          </Grid>
        </Grid>

        <Grid container padding={0} spacing={2} alignContent="center" alignItems="center">
          <Grid xs={5} lg={4}>
            <Typography variant="body2" className="label-title">
              Document Indemnities:
            </Typography>
          </Grid>
          <Grid xs={7} lg={8}>
            {!documentsIndemnities || !documentsIndemnities.length
              ? ''
              : documentsIndemnities.join(', ')}
          </Grid>
        </Grid>

        <Grid container padding={0} spacing={2} alignContent="center" alignItems="center">
          <Grid xs={5} lg={4}>
            <Typography variant="body2" className="label-title">
              {documentsMonetaryTransactionType === MonetaryTransactionType.COST
                ? 'Cost:'
                : 'Revenue:'}
            </Typography>
          </Grid>
          <Grid xs={3} lg={3}>
            {documentsMonetaryTransactionValueSum
              ? '$ ' + formatCurrencyPartsUSD(documentsMonetaryTransactionValueSum).amountPart
              : '-'}
          </Grid>
          <Grid xs={4} lg={5}></Grid>
        </Grid>

        <Box m={3} />

        <Grid container padding={0} spacing={2}>
          <Grid xs={12}>
            <Divider />
            <Box m={3} />
          </Grid>
        </Grid>

        <Grid container padding={0} spacing={2}>
          <Grid xs={12}>
            <Grid container padding={0} spacing={2} alignContent="center" alignItems="center">
              <>
                <Grid xs={6} md={3}>
                  <Typography variant="body2" className="label-title">
                    Start date:
                  </Typography>
                </Grid>
                <Grid xs={6} md={3}>
                  <DatePicker
                    value={startDate || null}
                    onChange={(date: Date | null) => {
                      onSetLegalFolder!((oldLegalFolder) => {
                        return {
                          ...oldLegalFolder,
                          startDate: date,
                        };
                      });
                      // setSelected({ ...selected, [headCell.id]: date });
                    }}
                    componentsProps={{
                      actionBar: { actions: ['clear'] as PickersActionBarAction[] },
                    }}
                    renderInput={(props) => (
                      <TextField variant="outlined" fullWidth size="small" {...props} />
                    )}
                  ></DatePicker>
                </Grid>
                <Grid xs={6} md={3}>
                  <Typography variant="body2" className="label-title">
                    End date:
                  </Typography>
                </Grid>
                <Grid xs={6} md={3}>
                  <DatePicker
                    value={endDate || null}
                    onChange={(date) => {
                      onSetLegalFolder!((oldLegalFolder) => {
                        return {
                          ...oldLegalFolder,
                          endDate: date,
                        };
                      });
                      // setSelected({ ...selected, [headCell.id]: date });
                    }}
                    componentsProps={{
                      actionBar: { actions: ['clear'] as PickersActionBarAction[] },
                    }}
                    renderInput={(props) => (
                      <TextField variant="outlined" fullWidth size="small" {...props} />
                    )}
                  ></DatePicker>
                </Grid>
                <Grid xs={6}></Grid>
              </>
            </Grid>
          </Grid>
        </Grid>

        <Grid container padding={0} spacing={2}>
          <Grid xs={12}>
            <Grid container padding={0} spacing={2} alignContent="center" alignItems="center">
              <>
                <Grid xs={12}>
                  <Grid container padding={0}>
                    <Grid xs={12}>
                      <Typography variant="body2" className="label-title">
                        notes:
                      </Typography>
                    </Grid>
                    <Grid xs={12}>
                      <TextField
                        variant="outlined"
                        size="small"
                        multiline
                        value={notes || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          onSetLegalFolder!((oldLegalFolder) => {
                            return {
                              ...oldLegalFolder,
                              notes: e.target.value,
                            };
                          });
                        }}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
